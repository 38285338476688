import React from 'react'
import HeroSection from './Home/HeroSection'

function Home() {
  return (
    <>
        <main>
            <HeroSection/>
        </main>
    </>
  )
}

export default Home
