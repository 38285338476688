import Topbar from "./component/Topbar";
import Seperators from "./component/Seperators";
import Footers from "./component/Footers";
import Home from "./component/Home"
import AboutUs from "./component/AboutUs/AboutUs";
import Contactus from "./component/ContactUs/Contactus";
import Services from "./component/Services/Services";

import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <div className="flex flex-col justify-between min-h-screen">
          <main>
            <Topbar/>
            <Seperators/>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/contact" element={<Contactus />} />
              <Route path="/service" element={<Services />} />
            </Routes>
          </main>
          <footer>
            <Seperators/>
            <Footers/>
          </footer>
        </div>
     </Router>
    </>
  );
}

export default App;
