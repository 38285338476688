import React from 'react'
import SocialMedia from '../SocialMedia'

function Contactus() {
  let items =[
     {
      "name": "Yahya Ghamudi",
      "designation": "General Manager",
      "location": "Budapest, Hungary",
      "contactNumber" : ""
    },
    {
      "name": "Aakash Mathiyazhagan",
      "designation": "Administrative HR Manager",
      "location": "Chennai, India",
      "contactNumber" : "+91 97905 79824"
    },{
      "name": "Raamprakaash S",
      "designation": "Regional Manager",
      "location": "Budapest, Hungary",
      "contactNumber" : "+91 97903 57724"
    },
    {
      "name": "Anusiya M",
      "designation": "Digital Marketing Expert",
      "location": "Kumbakonam, India",
      "contactNumber" : ""
    },
    {
      "name": "Dhinakaran K",
      "designation": "Field HR expert",
      "location": "Kumbakonam, India",
      "contactNumber" : "+91 90435 85282"
    },
  ]

  const mappedData = items.map((item)=>{

    return <div className='flex flex-col justify-center gap-y-1 2xl:mb-5 md:pb-0 sm:mb-0'>
    <div className=' text-4xl pl-4'>{item.name}</div>
    <div className=' text-1xl pl-5 font-medium'>{item.designation}</div>
    {item.contactNumber ? <div className=' text-1xl flex font-thin pl-4'><span><svg className='size-6 mr-1' viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_125_2)">
<path d="M76.2 19.64C68.72 12.12 58.76 8 48.16 8C26.32 8 8.52 25.8 8.52 47.64C8.52 54.64 10.36 61.44 13.8 67.44L8.2 88L29.2 82.48C35 85.64 41.52 87.32 48.16 87.32C70 87.32 87.8 69.52 87.8 47.68C87.8 37.08 83.68 27.12 76.2 19.64ZM48.16 80.6C42.24 80.6 36.44 79 31.36 76L30.16 75.28L17.68 78.56L21 66.4L20.2 65.16C16.92 59.92 15.16 53.84 15.16 47.64C15.16 29.48 29.96 14.68 48.12 14.68C56.92 14.68 65.2 18.12 71.4 24.36C77.64 30.6 81.04 38.88 81.04 47.68C81.12 65.84 66.32 80.6 48.16 80.6ZM66.24 55.96C65.24 55.48 60.36 53.08 59.48 52.72C58.56 52.4 57.92 52.24 57.24 53.2C56.56 54.2 54.68 56.44 54.12 57.08C53.56 57.76 52.96 57.84 51.96 57.32C50.96 56.84 47.76 55.76 44 52.4C41.04 49.76 39.08 46.52 38.48 45.52C37.92 44.52 38.4 44 38.92 43.48C39.36 43.04 39.92 42.32 40.4 41.76C40.88 41.2 41.08 40.76 41.4 40.12C41.72 39.44 41.56 38.88 41.32 38.4C41.08 37.92 39.08 33.04 38.28 31.04C37.48 29.12 36.64 29.36 36.04 29.32C35.44 29.32 34.8 29.32 34.12 29.32C33.44 29.32 32.4 29.56 31.48 30.56C30.6 31.56 28.04 33.96 28.04 38.84C28.04 43.72 31.6 48.44 32.08 49.08C32.56 49.76 39.08 59.76 49 64.04C51.36 65.08 53.2 65.68 54.64 66.12C57 66.88 59.16 66.76 60.88 66.52C62.8 66.24 66.76 64.12 67.56 61.8C68.4 59.48 68.4 57.52 68.12 57.08C67.84 56.64 67.24 56.44 66.24 55.96Z" fill="#78A55A"/>
</g>
<defs>
<clipPath id="clip0_125_2">
<rect width="96" height="96" fill="white"/>
</clipPath>
</defs>
</svg>
</span>{item.contactNumber}</div> : ""}
    <div className=' text-1xl flex font-thin pl-4'>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(239 68 68)" className="size-6">
    <path fillRule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
    </svg>
   {item.location}
    </div>
  </div>
  })

  return (
    <div className='p-16 pl-10 h-screen bg-backgroundGradient bg-cover  imageSection  w-screen pb-20   2xl:mb-0'>
     <div className='text-5xl font-medium pl-5 flex 2xl:ml-32'>Need help? Get in touch with our team for support and guidance.</div>
     <div className='flex pt-11 2xl:justify-around justify-between md:h-[600px]'>
      <div className=' bg-white/0'>
        {mappedData}
      </div>
      <div className='mapLocationAddition flex flex-col'>
        <div className='contactWrapper flex gap-10'>
          <div className='addressWrapper'>
            Address: 
            <div>17,Mahamariamman Kovil St,</div>
            <div className='mb-7'>(Old bus stand) Mannargudi,Tamil Nadu - 614001</div>
          </div>
          <div>
            Email: 
            <div>info@athanglobal.in</div>
          </div>
          {SocialMedia}
        </div>
        <iframe title='Athan Pan Global service location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7316.7896154775435!2d79.4499045598659!3d10.6594200738453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a554d408a302eb1%3A0x1a346bc96a91271!2sOLD%20BUS%20STAND%20MANNARGUDI!5e0!3m2!1sen!2sin!4v1717351255691!5m2!1sen!2sin" width="800" height="500" style={{"border":0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
     </div>
     
    </div>
  )
}

export default Contactus