import React from 'react'
import Banner from "../../Assets/businessman-holding-tablet-management-group-people-his-hand.jpg"
import { ApplyNow } from '../../Helper/helper';
function HeroSection() {

  return (
    <div className='flex  w-screen h-screen'>
      <div className='w-1/2 bg-gradient-to-l  from-darkBlue from-0% via-midBlue via-43% to-lightgrey via-100% flex-col'>
        <div className='title  text-white font-extrabold text-5xl mt-72 ml-6'> Where Talent Meets Triumph, Crafting Your Workforce Solution! </div>
        <div className='subTitle text-white ml-7 mt-2 text-1xl'>Simplify Your Hiring Process with Our HR and PR Services
        </div>
        <button onClick={ApplyNow} className='bg-gradient-to-br text-2xl ml-7 mt-3 from-orange-300 to-orange-700  text-transparent bg-clip-text'>Apply Now</button>
      </div>
      <div className='w-1/2'>
        <img className='h-screen' src={Banner} alt="Bussinessman-banner" />
      </div>
    </div>
  )
}

export default HeroSection