import React from 'react'
import SocialMedia from './SocialMedia'
function footer() {
  return (
    <div className=' h-24 w-screen flex justify-between'>
      <div className='mt-7 ml-7'>
        <div>
          KEEP IN TOUCH
        </div>
        <div>
        info@athanglobal.in
        </div>
      </div>
      {/* <div className='mt-7 ml-20'>
       <div>FIND US HERE</div>
       <div>
        Address
       </div>
      </div> */}
      <div className='mr-7 mt-7'>
        <div>FOLLOW US</div>
        <SocialMedia/>
      </div>
    </div>
  )
}

export default footer
