import React from 'react'
import SocialMedia from '../SocialMedia'

function AboutUs() {
  return (
   <div className='bg-backgroundGradient bg-cover'>
    <div className='flex flex-col w-screen ml-9 py-8 mr-24 gap-y-16'>
      <div className='flex flex-col'>
        <div className='text-4xl'>
              Welcome to <span className='bg-gradient-to-br  from-orange-300 to-orange-700  text-transparent bg-clip-text'>ATHAN</span>
            </div>
            <div className='text-1xl ml-1'>
              PAN GLOBAL SERVICES
            </div>
            <SocialMedia/>
        </div>
      <div className='flex'>
        <div className='w-1/2 flex flex-col'>
          <div className='text-2xl font-medium content-center'>
            At ATHAN,
          </div>  
          <div className='mt-2 mr-14'>
            <p className='text-xl font-light leading-10'>
            we understand the pivotal role that human resources play in the success of any organization. Whether you are a job seeker looking for the perfect opportunity or a company in need of talented individuals, we are here to bridge the gap between talent and opportunity.
            </p>
          </div>
        </div>
        <div className='w-1/2 flex justify-start'>
          <div className=' bg-pickerImage w-[600px] h-[300px] bg-cover bg-center rounded-3xl'>
         </div>
        </div>
      
      </div>
      <div className='flex'>
        <div className='w-1/2'>
          <div className=' bg-missionImage w-[680px] h-[310px] bg-cover bg-center rounded-3xl'>
        </div>
      </div>
        <div className='w-1/2'>
          <div className=' pr-10'>
            <div className=' text-2xl font-medium'>
              Our MISSION,
            </div>  
            <div className='mt-5 mr-14'>
              <p className='text-xl font-light leading-10'>
              Our mission is to connect skilled professionals with companies seeking top-tier talent. We strive to provide a platform that simplifies the hiring process for companies while offering job seekers access to a wide range of career opportunities. Commitment and customer satisfaction becomes key principle for our business mission, we take pride in committing to excellence, integrity, and professionalism in what we do
              </p>
            </div>
          </div>
          
        </div>
      
      </div>
      <div className='flex'>
        <div className='w-1/2 flex flex-col'>
          <div className='text-2xl font-medium content-center'>
            Our Vision,
          </div>  
          <div className='mt-2 mr-14'>
            <p className='text-xl font-light leading-10'>
            Our aim is to calibrate new heights in services through cooperative endeavor, by staying connected to direct employer and ensure a place in internationally acclaimed organizations
            </p>
          </div>
        </div>
        <div className='w-1/2 flex justify-start'>
          <div className=' bg-visionImage w-[600px] h-[300px] bg-cover bg-center rounded-3xl'>
         </div>
        </div>
      </div>
      <div className='thirdSection flex flex-col mb-28 gap-y-14 mt-4'>
        <div className='flex text-6xl justify-center'>Why Choose Us? </div>
        <div className='flex justify-center gap-x-16'>
          <div className='w-96 leading-loose border-2 border-solid rounded-md shadow-md p-5 hover:border hover:scale-125 duration-300 hover:border-orange-300'><span className=' text-xl mr-1'>Dedicated Support:</span>
          Our team is committed to providing personalized support to both job seekers and companies throughout the hiring process.
          </div>
          <div className='w-96 leading-loose border-2 border-solid rounded-md shadow-md p-5 hover:border hover:scale-125  duration-300 hover:border-orange-300'><span className=' text-xl mr-1'>Extensive Network: </span>
          With a vast network of job seekers and companies, we offer unparalleled access to talent and job opportunities.</div>
        </div>
        <div className='flex justify-center'>
          <div className='w-96 leading-loose border-2 border-solid rounded-md shadow-md p-5 hover:border hover:scale-125 duration-300 hover:border-orange-300'>
            <span className=' text-xl mr-1 '> Efficient Process: </span>
            Our streamlined process ensures that both job seekers and companies find the perfect match quickly and efficiently.
            </div>
          </div>
      </div>
    </div>
   </div>
  )
}

export default AboutUs
