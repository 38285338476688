import React from 'react'
function Services() {
  return (
    <div className='bg-backgroundGradient bg-cover'>
      <div className='ml-9 py-8 w-screen flex flex-col mr-24'>
        <div className='Services text-4xl'>Services</div>
        <div className='FirstSection mt-4 flex'>
          <div className='w-1/2'>
            <p className='leading-10 mr-10 '>We understand the challenges in real world with recruitment and relocation, here
              at Athan we aim to bring hassle free services tailored to meet all the real time
              requirements. For clients, we screen the candidates from India, Nepal, Bangladesh, etc
              with support from our strategic local partners. For candidates we make sure that everything is under
              radar.Our diverse professionals bring seasoned expertise to people offering personalised personalised solutions tailored to meet the unique needs.
            </p>
          </div>
          <div className='w-1/2'>
            <div className='bg-computerImage w-[600px] h-[300px] bg-cover bg-center rounded-3xl'></div>
          </div>
        </div>
        <div className='secondSection flex justify-center mt-8'>
          <div className='bg-opertaionImage w-[500px] h-[500px]  bg-cover bg-center rounded-3xl'></div>
        </div>
        <div className='thirdSection flex mt-5'>
          <div className='talentSourcing w-1/2'>
            <div className=' text-2xl'>Talent Sourcing</div>
            <p className='leading-10 mr-10 '>Our talent sourcing services connect businesses with top-tier professionals across diverse industries and skill sets. When staffing requirements are placed
                we plan in a way that our client’s
                expectation is met. Within our processes we
                ensure all the necessities.
                Our approach includes candidate sourcing, screening, and selection to ensure a perfect match for clients‘ requirements
              </p>
          </div>
          <div className='w-1/2'>
            <div className='bg-networkingImage w-[600px] h-[300px]  bg-cover bg-center rounded-3xl'></div>
          </div>
        </div>
        <div className='fourthSection flex mt-10'>
        <div className='w-1/2'>
              <div className='bg-dataProtectionImage w-[670px] h-[300px]  bg-cover bg-center rounded-3xl'></div>
            </div>
          <div className='dataManagement w-1/2  pr-20'>
              <div className=' text-2xl'>Data Management</div>
              <p className='leading-10 mr-10 '>We are concerned about your privacy and yes we keep ourselves updated with all the local regulations. We abide to Digital Personal Data Protection Act (DPDPA, 2023) along with the
  clients’ local regulation EU GDPR. With this your data and privacy is secured with us.</p>
            </div>
        </div>
        <div className='fifthSection flex mt-5'>
          <div className='embassyActivities w-1/2'>
            <div className=' text-2xl'>Embassy Activities</div>
            <p className='leading-10 mr-10 '>Major step after selection starts with relocation activities, we start with Background checks
  and follow with document preparation, Visa application, arranging appointments, representative for managing activities during appointment till passport handover.</p>
          </div>
          <div className='w-1/2'>
            <div className='bg-visaApplicationImage w-[600px] h-[300px]  bg-cover bg-center rounded-3xl'></div>
          </div>
        </div>
        <div className='sixthSection flex mt-10'>
        <div className='w-1/2'>
              <div className='bg-visaProcessImage w-[670px] h-[300px]  bg-cover bg-center rounded-3xl'></div>
            </div>
          <div className='travelArrangements w-1/2 pr-20'>
              <div className=' text-2xl'>Travel Arrangements and work commutation</div>
              <p className='leading-10 mr-10 '>Our comprehensive flight booking arrangements encompass every aspect of your travel needs, from securing reservations for international flights to crafting tailored itineraries that include accommodations and transportation arrangements. Our dedicated partners provides personalized concierge services, travel insurance, and any necessary itinerary adjustments to ensure a seamless and stress-free travel experience</p>
            </div>
        </div>
        <div className='seventhSection flex mt-5'>
          <div className='accommodation w-1/2'>
            <div className=' text-2xl'>Accommodation</div>
            <p className='leading-10 mr-10 '>Our accommodation services offer a seamless and environmental friendly experience, catering to every aspect of your stay. We curate a selection of accommodations tailored to clients preferences and budget. Our dedicated team ensures hassle-free bookings, handles special requests, and provides ongoing support throughout your stay in working region.</p>
          </div>
          <div className='w-1/2'>
            <div className='bg-roomImage w-[600px] h-[300px]  bg-cover bg-center rounded-3xl'></div>
          </div>
        </div>
        <div className='eightSection flex mt-10'>
        <div className='w-1/2'>
              <div className='bg-immigrationImage w-[670px] h-[300px]  bg-cover bg-center rounded-3xl'></div>
            </div>
          <div className='immigrationTasks w-1/2 pr-20'>
              <div className=' text-2xl'>Immigration tasks</div>
              <p className='leading-10 mr-10 '>Our region specific representative and lawyers aim in encompassing and arranging all teh legal documentation and residency permits. Our team provides guidance through the complexities of immigration laws and regulations, and offering solutions tailored to client’s specific needs. We strive to streamline the immigration process, enabling individuals with confidence and peace of mind.</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Services
