import React, { useState } from 'react'
import logo from "../Assets/logo.png"
import { Link } from 'react-router-dom'
import { ApplyNow } from '../Helper/helper'
import { useLocation } from 'react-router-dom';

const Appbar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [selectedPage, setSelectedPage] = useState(currentPath);
  
  return (
    <nav className='flex justify-between items-center border-solid border-5  h-16 bg-gradient-to-r from-white from-10% via-headerMid via-30% to-headerEnd to-52%'>
      <div className='athaLogo ml-5 pt-1'>
        <img className=' w-48 h-[4.2rem]' src={logo} alt='logo'></img>
      </div>
      <div className=''>
        <ul className='flex gap-8 mr-20'>
            <li>
            <Link to="/"><button onClick={()=>{
                setSelectedPage("/")
              }}
              className={ selectedPage === "/" ? "pb-1 border-b-2 border-logoOrange selected font-semibold" : "hover:bg-gradient-to-br from-orange-300 to-orange-700  text-transparent bg-clip-text bg-black font-semibold"} >HOME</button></Link>
            </li>
            <li>
            <Link to="/service"><button onClick={()=>{
                setSelectedPage("/service")
              }}
              className={ selectedPage === "/service" ? "pb-1 border-b-2 border-logoOrange selected font-semibold" : "hover:bg-gradient-to-br from-orange-300 to-orange-700  text-transparent bg-clip-text bg-black font-semibold"}>SERVICES</button></Link>
            </li>
            <li>
            <Link to="/about"><button onClick={()=>{
                setSelectedPage("/about")
              }}
              className={ selectedPage === "/about" ? "pb-1 border-b-2 border-logoOrange selected font-semibold" : "hover:bg-gradient-to-br from-orange-300 to-orange-700  text-transparent bg-clip-text bg-black font-semibold"} >ABOUT US</button></Link>
            </li>
            <li>
            <Link to="/contact"><button onClick={()=>{
                setSelectedPage("/contact")
              }}
              className={ selectedPage === "/contact" ? "pb-1 border-b-2 border-logoOrange selected font-semibold" : "hover:bg-gradient-to-br from-orange-300 to-orange-700  text-transparent bg-clip-text bg-black font-semibold"}>CONTACT US</button></Link>
            </li>
            <li>
              <button onClick={ApplyNow} className='font-bold bg-black hover:bg-gradient-to-br from-orange-300 to-orange-700  text-transparent bg-clip-text'>APPLY NOW</button>
            </li>
        </ul>
      </div>
    </nav>
  )
}
// Need to add router to the following routes. 
export default Appbar
