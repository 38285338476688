import React from 'react'

function seperator() {
  return (
    <div className='w-full h-1  bg-gradient-to-r from-white from-10%  via-sky-700 via-51%  to-white to-100%'>
    </div>
  )
}

export default seperator
